@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap");

:root {
  --bgColor: #eaa379;
  --bgColor2nd: #ec671b;
  --bgImageColor: #F6931F;
  --textColor: #0c0a0a;
  --textColor2nd: #1eb2a6;
  --blackColor: rgba(5, 5, 5, 0.8);
  --greyColor: #999999;
  --whiteColor: #ffffff;
  --icongrey: rgba(255, 255, 255, 0.2);
  --footerGrey: #EEEEEE;
  --innerfooterGrey: #E9E9E9;
  --halfwhite: #F7F5F6;
}


body {
  background-color: #f1f3f5 !important;
  font-family: 'Segoe UI', 'nunito sans';
  /* font-family: "Roboto", sans-serif, 'nunito sans'; */
  transition: all 500ms ease;
  viewport-fit: cover;
  /* Or other values as needed */
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

}

html {
  --antd-wave-shadow-color: none !important;
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  pointer-events: none;
}

.ant-btn {
  border-radius: 4px !important;
}

/* sidebar selected menu blue border */
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border: none !important;
}

.card {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 70%;
  border-radius: 5px;
  transition: all 500ms ease;
  margin: 10px 8px;
  height: 100%;
  overflow: hidden;
}

.view-card {
  height: 100px !important;
  width: 100px !important;
}

.subscription-card {
  border: 1px solid rgba(100, 100, 100, 0.3) !important;
  border-radius: 6px !important;
  margin-bottom: 40px !important;
  height: 440px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important; */
}

.subscription-title {
  font-size: 22px;
  height: 200px;
  font-weight: 700;
  padding-bottom: 100px;
  transition: 1s;
  border-bottom: 1px solid rgba(100, 100, 100, 0.3);
}

.subscription-title-new {
  font-size: 22px;
  font-weight: 500;
  transition: 1s;
  /* border-bottom: 1px solid rgba(100, 100, 100, 0.3); */
}

.questionFileUpload .ant-upload.ant-upload-select {
  width: 100% !important
}

.subscription-amount {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 50px;
  transition: 1s;
  margin-bottom: 60px;
}

.question_div {
  display: flex;
  justify-content: space-around;
}

.form-div-img {
  width: 365px;
  padding: 10px;
}

.form-div-img1 {
  width: 45%;
  /* padding: 10px; */
}

.form-div1Image {
  width: 45%;
  /* padding: 10px; */
}

.question-img1 {
  /* height: 350px; */
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 1.2);
}

.normalP {
  margin-top: 0;
  margin-bottom: 0;
}

.new-my-rate .ant-rate-star-full {
  color: var(--bgColor2nd) !important;
}

.form-feedback-student-main {
  border-radius: 8px;
  padding: 10px;
}




.feedback-student-inner-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.feedback-student-inner-2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
  max-width: 300px;
}

.feedback-student-inner-3 {
  text-align: center;
}

.postal-card {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important; */
  font-size: 20px;
  padding: 10px;
  margin: 10px 15px;
}

.editorClassName {
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  padding: 6px 5px 0;
}

.mui-size {
  font-size: 18px !important;
}

.modal-view {
  width: 70vw !important;
}

.subs-input {
  width: 100px !important;
}

.helper-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  /* overflow-x: hidden; */
  overflow-y: auto;
  transition: all 500ms ease;
}

.text-center {
  text-align: center;
}

.background-layout {
  background-image: url(./assets/background.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.taginput {
  margin-left: 0 1px;
}

.showMoreText {
  padding: 2px;
  width: 100px;
  font-size: 16px;
  background: white;
  font-weight: 600;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}

.slide>div>img {
  height: 500px !important;
}

.menu-header {
  position: fixed;
  top: 0 !important;
  z-index: 999;
  width: 100%;
  background-color: #1890ff;
}

.footer_div {
  text-align: center;
  height: 10vh;
  background-color: #007AFF;
  color: white;
  position: relative;
  padding: 15px 10px;
}

.social-login {
  border-radius: 4px !important;
  width: 100% !important;
  font-size: 16px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  padding: 0 !important;
  line-height: 1.5715 !important;
  height: 32px !important;
  margin: 0 !important;
}

.card1 {
  background-color: #ffffff;
  border: 1px solid #e0e0ee !important;
  width: 35%;
  padding: 40px 30px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
}

.icon-color {
  color: #1890ff;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.action-btn {
  display: flex;
  justify-content: center;
}

.mouse-effect {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-main {
  padding: 20px 30px 10px 0;
}

.button-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 20px 0;
}

.button-end-scroll {
  margin-right: 12%;
}

.user-table {
  width: 88%;
}

.Buselect {
  width: 30%;
}

.btn-end {
  border-radius: 4px !important;
  margin-top: 5px !important;
}

label {
  font-weight: 500 !important;
}

.google-login {
  margin: 10px 0 !important;
  border-radius: 4px !important;
  width: 100% !important;
  font-size: 16px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  padding: 0 !important;
  line-height: 1.5715 !important;
  height: 32px !important;
}

.heading {
  font-size: 24px;
  font-weight: bold;
}

.break-line {
  border-top: 0.5px solid #0000003a;
  margin: 0 -30px;
}

.setting-heading {
  font-size: 20px;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 5px;
}

.head-center {
  text-align: center;
}

.form-input {
  border-radius: 4px !important;
}

.form-button {
  border-radius: 4px !important;
  color: #ffffff !important;
}

.add-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  width: 95%;
}

.add-button {
  width: 200px;
}

.header {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 50px;
}

.drawer-header {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #ffffff;
}

.social-buttons {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}

.menu-flex {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.side-menu1 {
  flex: 1;
  /* height: 100%; */
  position: fixed;
  top: 64;
  /* z-index: 999; */
}

.side-menu {
  max-width: 12% !important;
  transition: 0.3s !important;
  position: relative !important;
}

.helper-comp {
  position: relative;
  min-height: calc(100vh - 64px);
  width: 100%;
  transition: all 500ms ease;
  margin-top: 64px;
}

.rmdp-container {
  display: block !important;
}

.rmdp-input {
  border-radius: 0px !important;
  height: 33px !important;
}

.errorColor {
  border: 1px solid red !important;

}

.side-list {
  font-size: 18px;
  transition: 0.3s !important;
}

.home-main {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
}

.card-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
}

.update-subscription {
  margin-left: 16px;
}

.product_code {
  width: 90%;
  text-align: right;
}

.product_search {
  width: 90%;
  /* text-align: right; */
}

.product_cards {
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1%;
}

.card_img {
  width: 100%;
}

.product_img {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
}

.product_Card {
  width: 24%;
  margin-right: 1%;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.card_title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0;
}

.card_desc {
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 0;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sponsor_badge {
  margin-bottom: 0;
  font-weight: 600;
  opacity: 0.3;
  margin-top: 0px;
}

.card_desc1 {
  font-size: 0.7em;
  font-weight: 600;
  margin-bottom: 0;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product_desc {
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  opacity: 0.5;
}

.card_sold {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0;
  opacity: 0.8;
}

.card_sold_price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product_Card .ant-card-body {
  padding: 15px 10px 5px 10px;
}

.card0 {
  background-color: #ffffff;
  border: 1px solid #e0e0ee !important;
  width: 90%;
  padding: 40px 30px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
  overflow: hidden;
}

.card2 {
  background-color: #ffffff;
  border: 1px solid #e0e0ee !important;
  width: 70%;
  padding: 40px 30px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
  overflow: hidden;
}

.form-card-inner {
  background-color: #ffffff;
  border: 1px solid #e0e0ee !important;
  width: 100%;
  padding: 40px 30px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
  overflow: hidden;
}

.carousel .slide {
  height: 540px;
}

.user-card {
  width: 70%;
  padding: 40px 30px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
  overflow: hidden;
}

.card3 {
  background-color: #ffffff;
  border: 1px solid #e0e0ee !important;
  width: 90%;
  padding: 40px 30px 20px;
  border-radius: 5px;
  transition: all 500ms ease;
  overflow: hidden;
  margin-bottom: 60px;
}

.verify-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.verify-text {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #1890ff;
}

.verify-head {
  margin-bottom: -10px;
  text-align: center;
  font-size: 80px;
  font-weight: 600;
  color: #1890ff;
}

.verify-div {
  height: 100vh;
  background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
  background-image: linear-gradient(180deg, #dfedf0, #e6dada);
}

.ant-select-selector {
  border-radius: 4px !important;
}

.card-box {
  display: flex;
  justify-content: center;
  margin: 20px 0 10px;
}

.form-layout {
  flex: 1;
  transition: 500ms;
}

.left-layout {
  margin-right: 3px !important;
}

.right-layout {
  margin-left: 3px !important;
}

.invite-guest {
  margin-right: 10px;
}

.card-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto !important;
}

.postal-logo {
  height: 120px;
}

/* Backside */

.line {
  border-left: 2px solid #000000;
  height: 330px;
  margin-top: 12px;
  margin-left: -3px;
}

.box {
  border: 2px solid #000000;
  height: 90px;
  width: 70px;
  position: absolute;
  right: 4px;
  top: 4px;
}

.message {
  margin-top: 30px;
  padding: 6px;
  word-wrap: break-word;
  overflow: hidden;
}

.right-box {
  margin-top: -210px;
}

.right-list {
  font-weight: 600;
  border-bottom: 2px solid #000000;
  width: 98%;
  margin-left: 2px;
}

.report-button {
  margin-left: 10px !important;
}

.div-container {
  display: flex;
  flex: 1;
  border: 2px solid #000000;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  height: 377.95px;
}

.div-child {
  flex: 1;
}

/* Addloyalty Component css */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.middleDiv {
  color: black;
}

.coupon_modal_input,
.Scan {
  width: 60%;
}

.middleDiv {
  width: 60%;
  text-align: center;
  border-bottom: 1px solid #bbb;
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-weight: bolder;
}

.middleDiv,
.middleDiv>span {
  background: #fff;
  padding: 0 10px;
}

.formContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.multipleSelector {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  /* justify-content: space-around; */
  /* display: inline; */
}

.close {
  position: absolute;
  right: -30px;
  top: 32px;
  width: 27px;
  height: 27px;
  opacity: 0.8;
  margin-top: -70px;
  color: black;
  /* text-align: center; */
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  /* content: ' '; */
  height: 33px;
  width: 2px;
  /* background-color: #003; */
}

.fixed-qr {
  position: fixed;
  top: -700px;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.button-div {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.btn-div {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
}

.btn-div {
  width: 140px !important;
}

.review-text {
  padding: 0;
  font-size: 14;
  margin: 0;
  line-height: 1.2;
}




.review-card-div {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  min-width: auto;
}

.review-card-div::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #007AFF;
  ;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

.review-card-div:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.progress-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10;
}

.progress-text-point-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.progress-inner-main-div {
  width: 48%;
}

.review-text-aim-2 {
  display: none;
}

.filter-main-div {
  display: flex;
  flex-direction: column;
}

.inner-filter-main-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.form-input-filter-select {
  width: 100% !important;
}

.form-input-select-filter-div {
  width: 100% !important;
  padding: 5px;
}


.admin-review-div {

  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-r-cards {
  width: 85%;
  margin: 10px auto;
}

.elipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0px;
  font-size: 14px;
  margin: 0px;
  line-height: 1.2px;
}

.countries-select-span {
  margin-top: 10px !important;
  padding-bottom: 9px;
}

.countries-select>button {
  border: none !important;
  background-color: #ec671b;
  width: 60px;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.countries-select-update-span {
  margin-top: 10px !important;
  padding-bottom: 9px;
}

.countries-select-update>button {
  border: none !important;
  background-color: #ec671b;
  width: 60px;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.EditDeleteIcon {
  color: white;
  background: rgb(236, 103, 27);
  margin-right: 2px;
  border-radius: 100px;
  padding: 5px;
  height: 30px;
  width: 30px;
}



/* ////////////////////////////////////////////////NEWNEWNEWNWENWENWENWENWNEWNE */
/* .ant-layout-sider {
  background-color: white;
} */

.new-side-menu.ant-layout-sider-has-trigger {
  padding: 0px !important;
  background-color: white;
}

.new-side-menu>.ant-layout-sider-trigger {
  background-color: #ec671b !important;
  display: none !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
  color: #ec671b !important;
  border-radius: 10px !important;
  border-right: 6px solid #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background-color: transparent !important;
  color: #ec671b !important;
  border-radius: 10px !important;
  border-right: 6px solid #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item a:hover {
  color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {
  color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover {
  color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-arrow {
  color: #ec671b !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: #ec671b !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span a span {
  color: #ec671b !important;
}


.my-menu-sh-new .ant-menu-item-selected .ant-menu-title-content a {
  background-color: transparent !important;
  color: #ec671b !important;
  border-radius: 10px !important;
  background-color: white !important;
}

.ant-menu-submenu-selected .ant-menu-title-content a {
  color: #ec671b !important;
}

.my-menu-sh-new .ant-menu-item-selected {
  background-color: transparent !important;
  color: #ec671b !important;
  border-radius: 10px !important;
  background-color: white !important;
  border-right: 6px solid #ec671b !important;
}

.my-menu-sh-new .ant-menu-submenu-selected {
  background-color: transparent !important;
  color: #ec671b !important;
  border-radius: 10px !important;
  background-color: white !important;
}

/*-----------------sidebar_Scroll---------------- */

.sidebar-scroll_class::-webkit-scrollbar {
  width: 4.5px !important;
  height: 3.5px !important;
  border-radius: 4px !important;
  background-color: rgba(76, 175, 80, 0) !important;
}

.sidebar-scroll_class::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  height: 3.5px !important;
  background-color: transparent;
}

.sidebar-scroll_class::-webkit-scrollbar-thumb {
  /* background: var(--color3) !important; */
  background: #ec671b !important;
  border-radius: 10px !important;
  height: 2px !important;
  /* display: none !important; */
}

/* NEW STYLING  */
.main-div-new-style {
  margin: 30px;
  margin-bottom: 0px;
  min-height: calc(100% - 60px);
}

.div-flex-back-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.div-flex-back-res {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 9px
}

.screen-heading {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.4;
  cursor: pointer;
  color: #ec671b;
}

.screen-back-icion {
  height: 35px;
  margin-right: 14px;
  cursor: pointer
}

.addproduct-text {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  background-color: #ec671b;
  color: white;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: none !important;
}

.addproduct-text:hover {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  background-color: #e39161;
  color: white;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: none !important;
}

.addproduct-text:focus {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  color: #e39161;
  background-color: white;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: none !important;
}

.cancel-btn {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  color: #ec671b;
  background-color: white;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #ec671b;
}

.cancel-btn:hover {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  color: #e39161;
  background-color: white;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #ec671b;
}

.cancel-btn:focus {
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  background-color: #e39161;
  color: white;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #ec671b;
}

.height-35 {
  height: 35px;
}

.new-input-search {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.new-input-search>.ant-input-wrapper {
  border: none !important;
  height: 45px !important;
}

.new-input-search>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  border: none !important;
  height: 45px !important;
}

.new-input-search>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child:focus {
  border: none !important;
}

.new-input-search .ant-btn-primary {
  background-color: #ec671b !important;
  border: none !important;
  height: 45px !important;
  width: 55px !important;
}

.new-input-search-guest {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.new-input-search-guest>.ant-input-wrapper {
  border: none !important;
  height: 45px !important;
}

.new-input-search-guest>span .ant-input-group-addon {
  border: none !important;
  height: 45px !important;
  border-radius: 4px !important;
}

.new-input-search-guest>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  border: none !important;
  height: 45px !important;
  border-radius: 4px !important;
}

.new-input-search-guest>.ant-input-wrapper.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child:focus {
  border: none !important;
}

.new-input-search-guest .ant-btn-primary {
  background-color: #ec671b !important;
  border: none !important;
  height: 45px !important;
  width: 55px !important;
}

.new-filter-btn {
  cursor: pointer;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-left: 8px;
  width: 80px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  align-items: center
}

.new-filter-btn:hover {
  opacity: 0.8;
}

.new-filter-btn-text {
  line-height: 1;
  margin: 8px;
  margin-top: 6px;

}

.new-delete-Btn {
  background-color: #ec671b;
  color: white;
  width: 49%;
  border: 1px solid #ec671b;
  border-radius: 4px;
}

.new-edit-Btn {
  background-color: white;
  color: #ec671b;
  width: 49%;
  border: 1px solid #ec671b;
  border-radius: 4px;
}

.new-delete-Btn:hover {
  background-color: #e39161;
  color: white;
  width: 49%;
  border: 1px solid #e39161;
  border-radius: 4px;
}

.new-delete-Btn:focus {
  background-color: #e39161;
  color: white;
  width: 49%;
  border: 1px solid #e39161;
  border-radius: 4px;
}

.new-edit-Btn:hover {
  background-color: white;
  color: #ec671b;
  width: 49%;
  border: 1px solid #ec671b;
  border-radius: 4px;
}

.new-setting-div {
  width: 80%;
  margin: 10px auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.setting-div-cards {
  width: 49%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  color: #ec671b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  margin-top: 20px;
  border-radius: 4px;
  transition: all 1000ms ease;
  cursor: pointer;
}

.setting-div-cards:hover {
  background-color: #ec671b;
  color: white;
}

.setting-div-cards p {
  margin-bottom: 0px;
  margin-top: 0px;
  width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.new-reservation-setting-form div div div .ant-table-container table>thead>tr:first-child th:first-child {
  background-color: #ec671b !important;
  border: 2px solid #ec671b !important;
}

.form-two-input-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}

.form-input-single-input {
  margin-top: 10px;
  width: 99%;
  margin-left: 0.5%;
}

.form-two-input-flex-inner-input {
  width: 49%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  padding-top: 10px;
}

/* .padding-none-class-for-showsearch-selct .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  padding-top: 0px !important;
  padding: 0px !important;
} */

.form-input-new-style {
  border-radius: 6px !important;
  height: 55px !important;
  border: none !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 2.5px 8px;
}

.form-input-new-style-for-filetr {
  border-radius: 6px !important;
  /* height: 55px !important; */
  border: none !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 2.5px 8px;
}

.form-input-new-style-for-filetr .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
  border: none !important;
  padding: 6px !important;
  height: 100% !important;
  padding: 12px !important;
}

.form-input-new-style:focus {
  border: none !important;
}

.form-input-new-style.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
  border: none !important;
  padding: 6px !important;
  height: 100% !important;
  padding: 12px !important;
}

.form-input-new-style.ant-select:not(.ant-select-customize-input) .ant-select-selector :focus {
  border: none !important;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper {
  -webkit-padding-start: 0px !important;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 10px !important;
  height: 100% !important;
}


.form-two-input-flex-inner-input .ant-upload span button {
  width: 100% !important;
  height: 55px !important;
  border-radius: 6px !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
  border: none !important;
}

.form-two-input-flex-inner-input .ant-upload span button:hover {
  width: 100% !important;
  height: 55px !important;
  border-radius: 6px !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
  border: none !important;
  color: black !important;
}

.form-two-input-flex-inner-input .ant-upload span button:focus {
  color: black !important;
  width: 100% !important;
  height: 55px !important;
  border-radius: 6px !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
  border: none !important;
}


.form-two-input-flex-inner-input .ant-upload {
  width: 100% !important;
  height: 55px !important;
  border-radius: 4px;

}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border: none !important;
  height: 55px !important;
  border-radius: 4px;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) :hover {
  border: none !important;
  height: 55px !important;
  border-radius: 4px;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) :focus {
  border: none !important;
  height: 55px !important;
  border-radius: 4px;
}

.payment-history-cards-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.payment-history-card {
  width: 48%;
  margin: 1%;
  background: white;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-left: 6px solid #ec671b;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 10px;
  padding-top: 4px
}



/* .ant-input-number-input-wrap input {
  color: black !important;
  width: 100% !important;
  border-radius: 6px !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
  border: none !important;
}

.ant-input-number {
  border: none !important;
} */
.auth-screen-main-div {
  width: 80%;
  padding: 30px;
  margin: 10px auto;
  background: #FBFBFB;
}

.guestHomeInfoHeading {
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #ffffff;
}

.GuestCardsTitle {
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  color: #ec671b;
}

.guest-card-tect-string {
  font-weight: bold;
  width: 160px;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
}

.guest-card-review-box {
  margin-right: 5px;
  height: 40px;
  width: 35px;
  padding: 0px;
  background: #ec671b;
  border: none;
  font-size: 11px;
  font-weight: 500;
  color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-card-style {
  height: 285px;
  width: 290px;
  min-width: 290px;
  border-radius: 4px;
  margin: 4px;
  margin-right: 10px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer
}

.cards-main-div-guest-all-crds {
  padding: 30px;
}

.detail-card-main-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detail-card-inner-div {
  width: 80%;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  text-align: center;
  margin: 0px auto;

}

.buyProduct_imges_main_div {
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1%;
}

.buyProduct_1st_img_div {
  width: 50%;
  padding: 4px;
  height: 350px;
}

.buyProduct_1st_img {
  height: 340px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 4px;
}

.buyProduct_2nd_img_div {
  width: 50%;
  padding: 4px;
  height: 350px
}

.buyProduct_2nd_img_div_inner_flex {
  display: flex;
  flex-direction: column
}

.img_div_inner_flex_1st {
  display: flex;
  flex-direction: row;
  height: 170px;
  justify-content: space-between;
}

.img_div_inner_flex_2nd {
  width: 100%;
  height: 167px;
  margin-top: 4px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 4px;
}

.img_div_img {
  width: 49%;
  height: 99%;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  border-radius: 4px;
}

.sideMenuHeader {
  height: 150px;
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center;
}

.sideMenuHeader-SidebarLogo {
  height: 80px;
  width: 100px;
}

.sideMenuHeader div p {
  color: black;
  font-weight: 800;
  /* font-family: 'fantasy'; */
  display: none;
}

.sideMenuHeader div .backIconHeader {
  display: none;
}

.laptopDiv {
  display: flex !important;
}

.mobileDiv {
  display: none !important;
}

.divFaqDiv {
  /* width: '80%', margin: '10px auto', marginTop: '30px' */
  width: 80%;
  margin: 10px auto;
  margin-top: 30px;
}

.tableValue {
  margin: 0px;
  width: 19%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MobileTable {
  display: none !important;
}

.DesktopTable {
  display: flex !important;
}

.voucherDetailVeiw {
  text-align: center;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  width: 90%;
  border-radius: 8px;
}

.autoCompleteErr .ant-select-selection-search-input {
  height: 100% !important;
}

.select-inner-padding-nonr .ant-select-selector {
  padding: 0px !important;
  padding: 0px !important;
  /* background-color: red !important; */
}

.newInpuHeightFull .ant-input-number-input-wrap {
  height: 100% !important;
}

.newInpuHeightFull-withMargin .ant-input-number-input-wrap {
  height: 100% !important;
  padding-top: 10px !important;
}

.button-flex-box {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.rmdpInputHeight .rmdp-input {
  border-radius: 6px !important;
  height: 55px !important;
  border: none !important;
  box-shadow: rgb(0 0 0 / 24%) 0px 2.5px 8px !important;
}

.card-container-new .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  height: 40px !important;
  background-color: #ec671b !important;
  color: white !important;
  border-radius: 4px;
}

.card-container-new .ant-tabs-tab:hover {
  color: #ec671b !important;
}

.card-container-new .ant-tabs-tab:active {
  color: #ec671b !important;
}

.card-container-new .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.card-container-new .ant-tabs-nav-wrap {
  border: 2px solid white !important;
}

.serchSe div .ant-select-selection-search {
  top: 11px !important;
}

.modal-menu-itm {
  color: white;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.modal-menu-itm:hover {
  border-bottom: 2px solid white;
}

.modal-menu-itm-active {
  border-bottom: 2px solid white;
  font-weight: bold;
}

.modal-menu-itm-child {
  margin-left: 40px;
  border-bottom: 2px solid white;
}

.modal-menu-itm-child:hover {
  margin-left: 40px;
  border-bottom: 2px solid white;
}

.modal-menu-itm-child-active {
  margin-left: 40px;
  font-weight: bold;
  border-bottom: 2px solid white;
}

/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* new css for new design */
.new-layout-scroll-class::-webkit-scrollbar {
  width: 4.5px !important;
  height: 3.5px !important;
  border-radius: 4px !important;
  background-color: var(--bgColor) !important;
}

.new-layout-scroll-class::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  height: 3.5px !important;
  background-color: transparent !important;
}

.new-layout-scroll-class::-webkit-scrollbar-thumb {
  background: var(--bgColor2nd) !important;
  border-radius: 10px !important;
  height: 2px !important;
}

.new-main-header-div {
  height: 180px;
  /* background-color: var(--bgColor); */
}

.new-upper-header {
  padding-left: 22px;
  padding-right: 22px;
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.upper-header-txt-logo-menu {
  font-weight: 800;
  font-size: 30px;
  color: var(--whiteColor);
  z-index: 3;
  position: relative;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0px;
  cursor: pointer;
}

.upper-header-txt-logo-menu span {
  color: var(--whiteColor);
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  font-weight: 500;
}

.upper-header-txt-logo {
  font-weight: 800;
  font-size: 30px;
  color: var(--whiteColor);
  z-index: 3;
  position: relative;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0px;
  cursor: pointer;
}

.upper-header-txt-logo:hover {
  color: var(--bgColor2nd)
}

.upper-header-txt-logo span {
  color: var(--whiteColor);
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  font-weight: 500;
}

.upper-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.uppr-icon-main {
  margin: 0px;
  margin-top: 5px;
}

.upper-header-icon {
  border-radius: 50%;
  margin-right: 4px;
  background: var(--icongrey);
  padding-top: 16px;
  padding-bottom: 10px;
  padding-right: 12px;
  padding-left: 12px;
  color: var(--bgColor2nd);
  cursor: pointer;
}

.upper-header-icon:hover {
  background: var(--bgColor2nd);
  color: white;
}


.round-lang-upper-header-icon {
  border-radius: 50%;
  margin-right: 4px;
  background: var(--icongrey);
  /* padding-top: 14px; */
  /* padding-bottom: 10px; */
  /* padding-right: 12px; */
  height: 42px;
  /* padding-left: 12px; */
  color: var(--bgColor2nd);
  margin-top: 2px;
}

.round-lang-upper-header-icon:hover {
  background: var(--bgColor2nd);
  color: white;
}

.round-countries-select-update>button {
  border: none !important;
  width: 48px !important;
  height: 32px !important;
  margin-top: 5px;
  margin-left: -7px;
  background-color: transparent !important;
  border-radius: 100px !important;
}

.round-countries-select-update>ul {
  margin-left: 6px !important;
  /* height: 80px !important; */
}

.round-countries-select-update>ul>li {
  padding: 5px !important;
  padding-top: 1px !important;
}


.round-countries-select-update>button>span>span>svg>g {
  border-radius: 100px !important;
}

.round-countries-select-update .ReactFlagsSelect-module_selectBtn__19wW7:after,
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
  display: none !important;
}

.new-lower-nav {
  /* padding: 2px 60px; */
  margin: 0px 22px;
  background-color: rgba(255, 255, 255, 0.085);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lower-nav-left {
  display: flex;
  flex-direction: row;
  margin-left: -10px;
}

.me-new-top-menu {
  padding-top: 6px !important;
  background: transparent !important;
  border: none !important;
  width: 800px !important;
}

.me-new-top-menu-home {
  padding-top: 6px !important;
  background: transparent !important;
  border: none !important;
  width: 690px !important;
}

.my-menu-txtt {
  color: white;
  font-weight: bold;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  margin: 0px;
  display: flex;
}

.new-header-sub-menu {
  padding: 20px;
  height: 45px;
}

.new-header-sub-menu>.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
  border-bottom: 2px solid white !important;
}

.new-menu-c-item-active {
  font-weight: bold;
  color: var(--bgColor2nd);
}

.new-menu-c-item {
  color: var(--whiteColor);
  font-weight: bold;
}

.new-menu-for-mobile {
  display: none;
}

.lower-nav-right {
  display: flex;
  flex-direction: row;
}

.button-triangle {
  width: 100px;
  height: 70px;
  background: var(--bgColor2nd);
  -webkit-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  transform: skewX(-20deg);
  content: '';
  margin: 0px;
  cursor: pointer;
}

.lower-nav-right-bton {
  margin: 0px;
  height: 70px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: var(--bgColor2nd);
  padding: 2.5rem;
  color: var(--whiteColor);
  border-radius: 0;
  display: inline-block;
  width: 160px;
  z-index: 0;
  letter-spacing: 2px;
  margin-left: -80px;
  cursor: pointer;
  text-align: center;
}

.footer-notification {
  height: 250px;
  background-color: var(--bgColor2nd);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-notification p {
  color: #fff;
  font-size: 27px;
  font-weight: 500;
  margin: 0px;
}

.new-footer-main-upper {
  width: 100%;
  background-color: var(--footerGrey);
}

.new-footer-main {
  width: 100%;
  background-color: var(--footerGrey);
  padding-top: 25px;
  padding-left: 10px;
  height: auto;
  margin-bottom: -20px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
}

.footer-inner-box {
  min-height: 280px;
  height: auto;
  width: 24%;
  margin: 2px;
  padding: 10px 24px 10px 24px;
}

.footer-inner-box {
  min-height: 280px;
  height: auto;
  width: 24%;
  margin: 2px;
  padding: 10px 24px 10px 24px;
}

.upper-footer-txt-logo {
  font-weight: 800;
  font-size: 30px;
  color: var(--blackColor);
  z-index: 3;
  position: relative;
  line-height: 1.1;
  text-transform: uppercase;
  margin: 0px;
  cursor: pointer;
  margin-top: 30px;
}

.upper-footer-txt-logo span {
  color: var(--bgColor2nd);
  line-height: 1.1;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  font-weight: 500;
}

.footer-gry-para {
  color: var(--greyColor);
  margin: 0px;
  margin-top: 30px;
  width: 250px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
}

.footer-icon-main {
  margin-top: 20px;
}

.footer-header-icon {
  border-radius: 50%;
  margin-right: 4px;
  background: var(--bgColor2nd);
  padding-top: 14px;
  padding-bottom: 10px;
  padding-right: 12px;
  padding-left: 12px;
  color: var(--whiteColor);
  cursor: pointer;
}

.footer-div-t {
  color: var(--blackColor);
  font-weight: bold;
  font-size: 22px;
  margin-top: 25px;
}

.footer-div-span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  color: var(--blackColor);
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
}

.footer-div-span:hover {
  color: var(--bgColor2nd);
  transform: translateY(-1.5px);
  transition: opacity 0.5s, transform 0.5s;
}

.footer-div-span p {
  margin-bottom: 0px;
  margin-left: 4px;
}

.teacher-login0linkin-foot {
  color: var(--bgColor2nd);
  text-align: end;
  margin-bottom: 0px;
  cursor: pointer;
  padding: 20px;
}

.new-main-layout-div {
  height: 100vh;
  background-color: var(--bgImageColor);
}

.new-header-men-icon {
  padding: 22px;
  color: #ffffff;
  background: var(--bgColor2nd);
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;

}

.my-modal-for-menu {
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
  position: initial !important;
}

.my-modal-for-menu>.ant-modal-content {
  width: 100% !important;
  height: 100vh !important;
  background-color: var(--bgColor2nd) !important;
}

.my-modal-for-menu>.ant-modal-content button {
  display: none !important;
}

.cloxe-div-end {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 40px;
}

.cloxe-div {
  background-color: var(--bgColor2nd);
  border-radius: 4px;
  color: var(--whiteColor);
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}

.my-modal-menu-div {
  height: calc(100vh - 140px);
  overflow-y: auto;
}


.new-main-layout-div {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(./assets/desktop.png);
}

.home-txt-div {
  margin-left: 60px;
  margin-right: 60px;
  padding-top: 80px;
}

.new-home-first-p {
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 2px;
  margin-bottom: 5px;
  position: relative;
  z-index: 0;
  text-shadow: 1px 1px 2px var(--bgColor2nd)
}

.new-home-snd-p {
  font-weight: 600;
  color: #fff;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px var(--bgColor2nd)
}

.new-home-trd-p {
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  letter-spacing: 2px;
  margin-bottom: 5px;
  position: relative;
  z-index: 0;
  text-shadow: 1px 1px 2px var(--bgColor2nd)
}

.home-snup-btn {
  background: var(--bgColor2nd) !important;
  border: 1px solid var(--bgColor2nd) !important;
  color: var(--whiteColor) !important;
  padding: 15px 25px 15px 25px;
  cursor: pointer;
  border-radius: 4px;
  -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px
}

.home-snup-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  transform: translateY(-0.8px);
  transition: opacity 0.5s, transform 0.5s;
}

.home-login-btn {
  background: var(--whiteColor) !important;
  border: 1px solid var(--whiteColor) !important;
  color: var(--bgColor2nd) !important;
  padding: 15px 25px 15px 25px;
  cursor: pointer;
  border-radius: 4px;
  -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 10px;
}

.home-login-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  transform: translateY(-0.8px);
  transition: opacity 0.5s, transform 0.5s;
}

.exam-transaprent-div {
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.screen-qoute {
  font-family: sans-serif;
  font-style: italic;
  font-weight: bold;
  color: var(--whiteColor);
  margin-bottom: 0px;
  font-size: 20px;
  text-align: center;
  text-shadow: 1px 1px 2px var(--bgColor2nd)
}

.new-layout-table-upper-div {
  margin: 10px 10px 0px 10px;
}


.new-layout-table-upper-div {
  margin: 10px 10px 0px 10px;
}

.new-layout-table thead {
  background-color: var(--bgColor2nd);
  color: var(--whiteColor);
}

.new-layout-table tr:nth-child(2n) td {
  background-color: #f1894d43 !important;
}


.new-layout-table {
  border-bottom: 10px solid var(--footerGrey);
  background-color: var(--bgColor2nd);
  border-radius: 8px;
  padding: 10px;
}

.new-layout-table .ant-table-thead>tr>th {
  background: none !important;
}

.new-layout-table .ant-table-tbody>tr>td {
  border: none !important;
}

.table-title-p {
  color: var(--whiteColor);
  margin: 0px;
  font-weight: bold;
  font-size: 14px;
}














.ant-btn {
  border-radius: 4px !important;
}

.ant-btn-primary {
  background: var(--bgColor2nd) !important;
  color: white !important;
  border: none !important;
}

.ant-btn-default {
  color: var(--bgColor2nd) !important;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  opacity: 0.6 !important;
}

.ant-btn-default:focus,
.ant-btn-default:hover {
  color: var(--bgColor2nd) !important;
  border-color: var(--bgColor2nd) !important;
  background: #fff;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: rgba(0, 0, 0, .25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.new-new-my-tabs .ant-tabs-tab-active div {
  color: var(--bgColor2nd) !important;
  font-weight: bold;
}

.new-new-my-tabs .ant-tabs-ink-bar {
  background-color: var(--bgColor2nd);
}

.new-new-my-tabs .ant-tabs-tab:hover {
  color: var(--bgColor2nd) !important;
}

.inner-div-pf {
  background: #ec681b78;
  border-radius: 6px;
  padding: 15px;
}

.p-pf {
  background: #ec681b78;
  border-radius: 4px;
  padding: 10px;
  color: var(--whiteColor);
}

.new-screen-heading {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.4;
  cursor: pointer;
  color: var(--bgColor2nd);

}

.nodata-center-text {
  padding: 30px 20px 20px;
  width: 100%;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  border-radius: 8px;
  text-align: center;
}

.new-exam-title {
  font-weight: 800;
  font-size: 30px;
  color: var(--blackColor);
  z-index: 3;
  position: relative;
  line-height: 1.1;
  text-transform: uppercase;
  padding-left: 10px;
  padding-top: 10px;
  margin: 0px;
}

.ant-spin-dot-item {
  background-color: #eaa379 !important;
}

.pop-info-modal .ant-modal-content {
  border-radius: 20px !important;
}

.pop-info-modal .ant-modal-body {
  padding: 14px !important;
}

.new-p-question {
  font-size: 20px;
  margin-bottom: 2px;
  text-align: center;
  background: #eaa279a3;
  color: var(--bgColor2nd);
  padding: 20px 0px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}


.three-boxes-card-div {
  background-color: var(--halfwhite);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.ex-tion-card {
  width: 23.5%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  border-radius: 10px;
  padding: 8px;
  margin: 3px;
  margin-top: 15px;
  background-color: var(--whiteColor);
}

.ex-tion-card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 8px 4px;
  transform: translateY(-3px);
  transition: opacity 0.5s, transform 0.5s;
}


.div-tt {
  margin-top: 4px;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 19px;
  background-color: var(--whiteColor);
  border-radius: 10px;
}

.ex-tion-card-button {
  background-color: var(--bgColor2nd);
  color: var(--whiteColor);
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.home-2nd-div-white {
  background-color: var(--halfwhite);
  display: flex;
  flex-direction: row;
}

.home-2nd-div-inner-white-inner-img-div {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.home-2nd-div-inner-white-img {
  height: 600px;
  width: 400px;

}

.home-2nd-div-1st-p {
  font-size: 16px;
  display: block;
  margin-bottom: 5px;
  color: var(--bgColor2nd);
  font-weight: 700;
  text-transform: uppercase;
}

.home-2nd-div-2nd-p {
  font-size: 40px;
  display: block;
  margin-bottom: 5px;
  color: var(--blackColor);
  font-weight: 500;
  line-height: 0.9;
}

.div-services-wrap {
  margin-top: 10px;
  height: 160px;
  background-color: var(--footerGrey);
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;
  cursor: pointer;
  color: var(--greyColor);
}

.div-services-wrap:hover {
  background-color: var(--bgColor2nd);
  color: var(--whiteColor) !important;
  transform: translateY(-0.8px);
  transition: opacity 0.5s, transform 0.5s;
}

.div-services-wrap:hover .new-pp-title {
  color: var(--whiteColor) !important;
}

.div-services-wrap div {
  padding: 10px;
}

.new-pp-title {
  color: var(--blackColor);
  font-size: 20px;
  font-weight: 700;
  font-family: "Roboto", Arial, sans-serif;
  margin: 0px;
}

.new-pp-dics {
  font-size: 14px;
  margin: 0px;
  font-weight: 400;

}

.hoame-3rd-div-count-up {
  background-color: var(--halfwhite);
}

.hoame-3rd-div-count-up .new-1st {
  /* height: 200px; */
  padding: 40px 0px 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.count-up-diiner-content {
  margin-left: 5px;
}

.count-up-t {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 700;
  display: block;
  text-transform: uppercase;
  margin: 0px;
}

.count-up-n {
  font-family: "Roboto", Arial, sans-serif;
  margin: 0px;
  font-size: 20px;
  line-height: 1.8;
  font-weight: 1000;
  display: block;
  text-transform: uppercase;
}


.home-4th-div {
  background-color: var(--halfwhite);
  padding: 0px 30px 0px 30px;
}

.home-4th-div-1p {
  font-size: 46px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--blackColor);
  text-align: center;
  margin: 0px;
  padding-top: 20px;
}

.courses-main-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.cours-div {
  margin: 4px;
  border-radius: 6px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
  width: 23%;
  height: auto;
  padding: 24px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.cours-divicon-div {
  color: var(--bgColor2nd);
}

.cours-div:hover {
  color: var(--whiteColor);
  background-color: var(--bgColor2nd);
}

.cours-div:hover .cours-divicon-div {
  color: var(--whiteColor);
}

.cours-div-tt {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
  font-family: "Roboto", Arial, sans-serif;
  margin: 0px;
}

.cours-div-nn {
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--whiteColor);
  color: var(--bgColor2nd);
  width: 100px;
  margin: 0px auto;
  padding: 2px 4px 2px 4px;
  font-weight: bold;
}


.cours-div {
  /* Your initial styles here */
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.cours-div.visible {
  opacity: 1;
  transform: translateY(0);
}

.margin0 {
  margin: 0px;
}


.home-2nd-div-inner-white {
  padding: 10px;
  width: 50%;
}


.count-up-div {
  margin: 10px;
  height: 120px;
  width: 240px;
  background-color: var(--footerGrey);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--blackColor);
  padding: 10px;
  border-radius: 4px;
}

.count-up-div:hover {
  background-color: var(--bgColor2nd);
  color: var(--whiteColor);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  transform: translateY(-1.5px);
  transition: opacity 0.5s, transform 0.5s;

}

.count-up-diiner-content {
  margin-left: 5px;
}

.visiter-faq-div {
  background-color: var(--halfwhite);
  width: 100%;
  padding: 10px;

}


.login-alert-paragraph {
  margin: 0px auto;
  text-align: center;
  color: var(--blackColor);
  cursor: pointer;
  width: fit-content;
}

.login-alert-paragraph>span {
  color: var(--bgColor);
}

.login-alert-paragraph>span:hover {
  color: var(--bgColor2nd);
  font-weight: bold;
  transition: all 500ms ease;
}

.login-alert-paragraph-border {
  border-bottom: 1px solid var(--bgColor);
  margin-top: 20px;
}

.login-alert-paragraph-border>span {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: -11px;
  position: absolute;
  background: var(--whiteColor) !important;
  align-items: center;
  left: 48%;
  width: 22px;
}

/* .ant-menu>.ant-menu-sub> */
.ant-menu-vertical {
  background-color: var(--bgColor2nd) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border: 1px solid var(--bgColor2nd) !important;
  background-color: var(--bgColor2nd) !important;
}

.teacher-button-menu-end {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

/* .ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: transparent !important;
}
 
.ant-menu-item a:hover {
  color: transparent !important;
  border-bottom: 0px;
}

.ant-menu-item-selected a:hover {
  color: transparent !important;
  border-bottom: 0px;
} */

.ant-menu-horizontal>.ant-menu-item a:hover {
  color: transparent !important;
  border-bottom: 0px;

}

.ant-menu-horizontal>.ant-menu-item a:hover {
  color: transparent !important;
  border-bottom: 0px;

}

.subscription-cards-main {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  padding: 4px
}

.subscription-cards {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  margin-right: 10px;
  height: 325px;
  box-shadow: 0px 0px 9px darkgrey;
  border-radius: 8px
}

.crt-question-div {
  padding: 20px 10px 20px 10px;
  margin: 10px auto;
  border-radius: 4px;
  width: 100%;
}


.student_radio {
  min-width: 150px;
  width: 100% !important;
  word-break: break-all;
  /* height: 50px !important; */
  padding: 10px 10px !important;
  background: white !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  margin-bottom: 8px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px !important;
}

.student_radio2 {
  min-width: 150px;
  width: 100% !important;
  word-break: break-all;
  /* height: 50px !important; */
  padding: 10px 10px !important;
  /* background: white !important; */
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  margin-bottom: 8px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px !important;
}



.hotspot_image {
  width: 150px !important;
}

.hotspot_radio .ant-radio {
  display: none !important;
}


.header-with-text {
  background: rgb(242 156 106 / 79%);
  padding: 2px;
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.student-part-scroll_class::-webkit-scrollbar {
  width: 4.5px !important;
  height: 3.5px !important;
  border-radius: 4px !important;
  background-color: rgba(76, 175, 80, 0) !important;
}

.student-part-scroll_class::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  height: 3.5px !important;
  background-color: transparent;
}

.student-part-scroll_class::-webkit-scrollbar-thumb {
  /* background: var(--color3) !important; */
  background: #CBE7F4 !important;
  border-radius: 10px !important;
  height: 2px !important;
  /* display: none !important; */
}

.main-scroll-div {
  padding: 15px 5px 5px;
  height: calc(100vh - 106px);
  overflow-y: scroll;
}


.student-main-div {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.padd-left-21 {
  padding-left: 21px;
}

.test-footer {
  background: var(--bgColor);
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  position: absolute;
  bottom: 0pc;
  width: 100%;
  z-index: 99999999;
}


/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* Media for new screen */

@media (max-width: 900px) {

  .hotspot_image {
    width: 120px !important;
  }
}

@media (max-width: 700px) {

  .hotspot_image {
    width: 70px !important;
  }

  .form-div-img1 {
    width: 100%;
  }

  .form-div1Image {
    width: 100%;
    margin-top: 25px;
  }

  .question_div {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .new-home-snd-p {
    font-size: 25px;
  }
}



@media (max-width: 800px) {
  .new-main-layout-div {
    background-image: url(./assets/tablet2bg.png);
    background-size: 100%;
  }
}

@media (max-width: 600px) {
  .home-txt-div {
    margin-top: 30px;
    padding-top: 0px;
  }


  .new-main-layout-div {
    background-image: url(./assets/mobilr.png);
    background-size: 100%;
    /* background-size: 100% 100%; */
  }

  /* .home-div-bg-img {
    height: 400px;
  } */

  .home-newbg-img {
    height: 310px;
  }
}

@media (max-width: 1010px) {
  .footer-inner-box {
    width: 32%;
  }

  .new-exam-card {
    width: 32%;
  }

  .footer-inner-box {
    width: 32%;
  }

  .three-boxes-card-div {
    justify-content: flex-start;
  }

  .ex-tion-card {
    width: 32%;
  }

  .new-result-card {
    width: 32%;
  }
}



@media (max-width: 860px) {
  .dashboard-inner-div-same-page {
    width: 48.5%;
  }
}

@media (max-width: 800px) {
  .new-exam-card {
    width: 49%;
  }

  .footer-inner-box {
    width: 49%;
  }

  .ex-tion-card {
    width: 49%;
  }

  .new-result-card {
    width: 49%;
  }
}

/* @media (max-width: 550px) {
  .count-up-div {
    width: 99%;
  }

  .ex-tion-card {
    width: 99%;
    margin-top: 10px;
  }

  .new-exam-card {
    width: 99%;
  }

  .footer-inner-box {
    width: 99%;
  }

  .new-result-card {
    width: 99%;
  }

  .cours-div {
    width: 99%;
    margin: 0.5%;
  }

  .voucherDetailVeiw {
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    width: 90%;
    border-radius: 8px;
    padding: 10px;
  }
} */

@media (max-width: 1100px) {
  .new-menu-for-web {
    display: none !important;

  }

  .new-menu-for-mobile {
    display: flex;
  }
}


@media (max-width: 900px) {
  .new-upper-header {
    padding: 22px;
  }

  .home-txt-div {
    margin-left: 20px;
  }


  .new-lower-nav {
    margin: 2px 22px;
  }
}

@media (max-width: 510px) {
  .uppr-icon-main {
    display: none;
  }
}

@media (max-width: 800px) {
  .div-1st-new {
    display: none;
  }

  .home-2nd-div-inner-white {
    width: 100%;
  }

  .home-2nd-div-white {
    display: flex;
    flex-direction: column;
  }
}


@media (max-width: 900px) {
  .home-2nd-div-2nd-p {
    font-size: 25px;
  }
}

@media (max-width: 910px) {
  .cours-div {
    width: 32%;
  }
}

@media (max-width: 750px) {
  .cours-div {
    width: 48%;
    margin: 0.5%;
  }

  .ex-tion-card {
    width: 48%;
    margin: 0.5%;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {

  .ex-tion-card {
    width: 98%;
    margin-top: 10px;
  }
}

@media (max-width: 450px) {
  .new-lower-nav {
    background-color: var(--bgColor2nd);
  }
}

@media (max-width: 400px) {
  .home-snup-btn {
    width: 100%;
    display: block;
  }

  .home-login-btn {
    width: 100%;
    display: block;
    margin-top: 4px;
    margin-left: 0px;
  }
}

@media (max-width: 550px) {
  .count-up-div {
    width: 99%;
  }

  .new-exam-card {
    width: 99%;
  }

  .footer-inner-box {
    width: 99%;
  }

  .new-result-card {
    width: 99%;
  }

  .cours-div {
    width: 99%;
    margin: 0.5%;

  }

  .footer-notification p {
    font-size: 18px;
  }


  .voucherDetailVeiw {
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    width: 90%;
    border-radius: 8px;
    padding: 10px;
  }

  .new-exam-title {
    font-size: 24px;
  }

  .subscription-cards-main {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: visible;
  }

  .subscription-cards {
    width: 100%;
    height: auto;
    min-width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 450px) {
  .footer-notification p {
    font-size: 14px;
  }

}



/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////////////// */

/* @media For Mobile */
@media (max-width: 550px) {
  .voucherDetailVeiw {
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    width: 90%;
    border-radius: 8px;
    padding: 10px;
  }
}

@media (max-width: 700px) {


  .DesktopTable {
    display: none !important;
  }

  .MobileTable {
    display: flex !important;
  }

  .divFaqDiv {
    /* width: '80%', margin: '10px auto', marginTop: '30px' */
    width: 100%;
    margin: 10px auto;
    margin-top: 30px;
  }

  .buyProduct_imges_main_div {
    height: auto !important;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 98%;
  }

  .buyProduct_1st_img_div {
    width: 100%;
    padding: 4px;
    height: 350px;
  }

  .buyProduct_2nd_img_div {
    width: 100%;
    padding: 4px;
    height: 350px
  }

  .main-div-new-style {
    padding: 10px;
    margin-bottom: 0px;
  }

  .laptopDiv {
    display: none !important;
  }

  .mobileDiv {
    display: flex !important;
  }

  .new-side-menu>.ant-layout-sider-trigger {
    display: none !important;
  }

  .sideMenuHeader {
    background: #ec671b;
  }

  .sideMenuHeader-SidebarLogo {
    display: none;
  }

  .sideMenuHeader div .backIconHeader {
    display: flex;
    cursor: pointer;
  }

  .sideMenuHeader div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
  }

  .sideMenuHeader div p {
    color: white;
    font-weight: 200;
    font-family: 'fantasy';
    margin: 0px;
    display: block;
  }
}

/* @media For Mobile */
@media (max-width: 900px) {

  .buyProduct_imges_main_div,
  .buyProduct_1st_img_div,
  .buyProduct_2nd_img_div {
    height: 300px;
  }

  .buyProduct_1st_img {
    height: 290px;
  }

  .img_div_inner_flex_2nd {
    height: 145px;

  }

  .img_div_inner_flex_1st {
    height: 142px;

  }
}

@media (min-width: 1500px) {
  .helper-main {
    width: 1450px;
    justify-content: center;
    margin: 0px auto;
  }

  .new-main-layout-div {
    width: 1450px;
    justify-content: center;
    margin: 0px auto;
  }
}

@media (max-width: 850px) {
  .auth-screen-main-div {
    padding: 30px;
    width: 100%;
    margin: 10px auto;
    background: #FBFBFB;
  }

}

@media (max-width: 900px) {
  .detail-card-main-div {
    display: flex;
    flex-direction: column;
  }

  .detail-card-inner-div {
    width: 100%;
  }
}




@media (max-width: 750px) {
  .payment-history-cards-div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cards-main-div-guest-all-crds {
    padding: 10px;
  }

  .payment-history-card {
    width: 100%;
  }
}


@media (max-width: 700px) {
  .form-two-input-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .form-two-input-flex-inner-input {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .new-setting-div {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .new-setting-div {
    flex-direction: column;
  }

  .setting-div-cards {
    width: 95%;
  }

  .div-flex-back-res {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .main-div-new-style {
    margin: 10px;
    margin-bottom: 0px;
  }

  .addproduct-text {
    width: 120px;
  }

}

@media (max-width: 600px) {
  .review-text-aim {
    display: none;
  }

  .review-text-aim-2 {
    display: flex;
  }

  .admin-r-cards {
    width: 100%;
  }


}

@media (max-width: 550px) {
  .filter-main-div {
    display: flex;
    flex-direction: column;
  }

  .inner-filter-main-div {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .button-end-scroll {
    margin-right: 13%;
  }

  .user-table {
    width: 87%;
  }
}

@media (max-width: 1200px) {
  .card1 {
    width: 45%;
  }

  .button-end-scroll {
    margin-right: 16%;
  }

  .user-table {
    width: 84%;
  }

  .menu-main {
    padding: 20px 20px 10px 0;
  }

  .subscription-title {
    font-size: 20px;
    height: 180px;
    font-weight: 700;
    padding-bottom: 100px;
    transition: 1s;
    border-bottom: 1px solid rgba(100, 100, 100, 0.3);
  }
}

@media (max-width: 1000px) {
  .card1 {
    width: 60%;
  }

  .modal-view {
    width: 80vw !important;
  }

  .button-end-scroll {
    margin-right: 18%;
  }

  .user-table {
    width: 82%;
  }

  .menu-main {
    padding: 20px 18px 10px 0;
  }

  .product_cards {
    padding-left: 1%;
  }

  .product_Card {
    width: 32%;
    margin-right: 1%;
  }
}

@media (max-width: 900px) {
  .card {
    width: 80%;
  }

  .card1 {
    width: 65%;
  }

  .card2 {
    width: 80%;
  }

  .modal-view {
    width: 85vw !important;
  }

  .button-end-scroll {
    margin-right: 21%;
  }

  .user-table {
    width: 79%;
  }

  .menu-main {
    padding: 20px 17px 10px 0;
  }

  .product_cards {
    padding-left: 1%;
  }

  .product_Card {
    width: 49%;
    margin-right: 1%;
  }
}

@media (max-width: 800px) {
  .card {
    margin: 10px 6px;
  }

  .card1 {
    width: 70%;
  }

  .tennis-button>span {
    font-size: 12px !important;
  }

  .modal-view {
    width: 90vw !important;
  }

  .button-end-scroll {
    margin-right: 23%;
  }

  .user-table {
    width: 77%;
  }

  .menu-main {
    padding: 20px 16px 10px 0;
  }
}

@media (min-width: 700px) {
  .form-button {
    margin-top: 5px;
  }

}

@media (max-width: 700px) {
  .card1 {
    width: 75%;
  }

  .card2,
  .card3 {
    width: 90%;
    padding: 30px 20px 20px;
  }

  .tennis-button>span {
    font-size: 10px !important;
  }

  .modal-view {
    width: 95vw !important;
  }

  .button-end-scroll {
    margin-right: 28%;
  }

  .user-table {
    width: 72%;
  }

  .menu-main {
    padding: 20px 12px 10px 0;
  }

  .update-subscription {
    margin-top: 16px;
    margin-left: 0;
    padding: 2px !important;
  }

  .product_cards {
    padding-left: 0%;
  }

  .product_Card {
    width: 100%;
    margin-right: 0%;
  }
}

@media (max-width: 600px) {
  .Buselect {
    width: 50% !important;
  }

  .card1 {
    width: 80%;
    padding: 20px 20px 10px;
  }

  .break-line {
    margin: 0 -20px;
  }

  .flex-mobile {
    flex-direction: column;
  }

  .left-layout {
    margin-right: 0 !important;
  }

  .right-layout {
    margin-left: 0 !important;
  }

  .card2 {
    width: 95%;
  }

  .card {
    width: 88%;
  }

  .modal-view {
    width: 98vw !important;
  }

  .button-end-scroll {
    margin-right: 30%;
  }

  .user-table {
    width: 70%;
  }

  .menu-main {
    padding: 20px 0px 10px 0;
    margin-left: -12px;
    margin-right: -12px;
  }

  .helper-comp {
    margin-top: 56px;
  }

  .subscription-card {
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media (max-width: 557px) {
  .invite-guest2 {
    margin-right: 0px !important;
  }
}

@media (max-width: 500px) {
  .card1 {
    width: 85%;
  }

  .heading {
    font-size: 20px;
  }

  .card2,
  .card3 {
    width: 96%;
    padding: 15px 10px 20px;
  }

  .card {
    width: 98%;
  }

  .flex-mobile {
    flex-direction: column !important;
  }

  .flex-mh {
    width: 250px !important;
  }

  .report-button {
    margin-left: 0 !important;
  }

  .invite-guest {
    margin-right: 0px;
  }

}

@media (max-width: 500px) and (min-width:370px) {
  .invite-guest3 {
    margin-right: 5px !important;
  }
}

@media (max-width: 450px) {
  .invite-guest {
    margin-right: 0px;
  }
}

@media (max-width: 450px) {
  .progress-main-div {
    display: flex;
    flex-direction: column;
  }

  .progress-inner-main-div {
    width: 100%;
  }

  .invite-guest {
    margin-right: 0px;
  }
}

@media (max-width: 400px) {
  .card1 {
    width: 90%;
  }

  .invite-guest {
    margin-right: 0px;
  }

  .Buselect {
    width: 50% !important;
  }
}

@media (max-width: 300px) {

  .card,
  .card1 {
    width: 95%;
  }

  .heading {
    font-size: 18px;
  }
}